import React from 'react';
import {Container, Grid, Segment,} from 'semantic-ui-react';
import styles from "./aboutUs.module.css";
import {graphql, StaticQuery} from "gatsby";
import Img from "../utils/Img";
import cx from "classnames";

const Trustees = () => (
    <div className={styles.main}>
        <StaticQuery query={trusteeItemsQuery}
                     render={
                         data => {
                             const Items = data.allMarkdownRemark.edges.map(i =>
                                     <Segment>
                                         <Grid stackable columns={2}>
                                 <Grid.Row stackable columns={2}>
                                     <Grid.Column width={6}>
                                         <Img fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                                     </Grid.Column>
                                     <Grid.Column width={10}>
                                         <p className={styles.name}>{i.node.frontmatter.title}</p>
                                         <Container
                                             className={cx(styles.details, styles.container)}
                                             dangerouslySetInnerHTML={{__html: i.node.html}}/>
                                     </Grid.Column>
                                 </Grid.Row>
                                         </Grid>
                                     </Segment>
                             );
                             return (<>{Items}</>)
                         }
                     }
        />
    </div>

);

export default Trustees;

const trusteeItemsQuery = graphql`
query trusteeItemsQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {
            type: { eq: "person" }
            kind: { eq: "trustees" }
         }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
            html
            frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
            }
        }
      }
    }
}
`;